<template>
	<div class="queryTable">
		<div>
			<!-- <p class="quest-deil">基本信息：</p> -->
			<div class="user-info">
				<p><span>姓名:</span><span class="line"></span></p>
				<p><span>性别:</span><span class="line"></span></p>
				<p><span>年龄:</span><span class="line"></span></p>
				<p><span>病区:</span><span class="line"></span></p>
				<p><span>床号:</span><span class="line"></span></p>
			</div>
			<div class="user-info" style="flex-wrap: wrap;">
				<p style="min-width:80px;margin-top: 5px;"><span>文化程度:</span></p>
				<p v-for="item in cultureList" :key="item" style="min-width:70px;margin-top: 5px;"><el-checkbox style="margin-right: 10px;"></el-checkbox>{{item}}</p>
			</div>
			<div class="user-info">
				<p><span>婚姻状况:</span></p>
				<p v-for="item in archivesList" :key="item"><el-checkbox style="margin-right: 10px;"></el-checkbox>{{item}}</p>
			</div>
			<div v-for="item,index in export_scales" :key="index">
				<p class="scale-title">({{toChinesNum(index + 1)}})&nbsp;&nbsp;{{item.measure_title}}</p>
				<p v-html="item.describe" class="measure-msg"></p>
				<!-- <div>
					<p v-if="export_scale.questions.length > 0" class="quest-deil mag">
						题目详情：
					</p>
				</div> -->
				<div v-for="itm in item.questions" :key="item.id">
					<div class="queryTable-title " style=" color: #044d5a">
						<span v-html="itm.question"></span>
					</div>
					<div class="queryTable-item-box">
						<div class="queryTable-item" style="page-break-inside: avoid;, margin-left: 0px" v-for="(item2, key2) in itm.options"
							:key="key2">
							<p>
								<el-checkbox style="margin-left: 10px;"></el-checkbox>
								<span>{{item2}}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<p class="quest-deil advice">诊断意见：</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			export_scales: {
			  type: Array,
			  default() {
			    return [];
			  },
			},
		},
		data(){
			return{
				cultureList:['文盲',"小学","初中","高中/中专/技校","大学专科","大学本科","硕士","博士及以上"],
				archivesList:['未婚',"已婚","离婚","丧偶","其它"],
				UNIT:["千","","十","百","万","亿"],
				UNUM:["零","一","二","三","四","五","六","七","八","九"]
			}
		},
		methods:{
			 //完成将 toChineseNum， 可以将数字转换成中文大写的表示，处理到万级别，例如 toChineseNum(12345)，返回 一万二千三百四十五。
			toChinesNum (num){
			    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
			    let unit = ["", "十", "百", "千", "万"];
			    num = parseInt(num);
			    let getWan = (temp) => {
			        let strArr = temp.toString().split("").reverse();
			        let newNum = "";
			        for (var i = 0; i < strArr.length; i++) {
			            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
			        }
			        return newNum;
			    }
			    let overWan = Math.floor(num / 10000);
			    let noWan = num % 10000;
			    if (noWan.toString().length < 4) noWan = "0" + noWan;
			    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
			    
			}
			// Reserve(S){
			//      //return S.split("").reverse().join("");
			//      let tmp="";
			//       for(let i=0;i<S.length;i++){
			//          tmp=S.charAt(i)+tmp;
			//      }
			//       return tmp;
			//  },
			 
			//  ToFullUpper(n){
			//       let S=this.Reserve(n);
			//       let R="";
			//       for (let i=0;Len=S.length,i<Len;i++)
			//       {
			//           //如果是第9位的倍数,这么这个一定是亿位
			//           if (i%8==0  && i!=0 )
			//              R=this.UNUM[S.charAt(i)]+this.UNIT[5]+R
			//           //如果是第5位的倍数,这么这个一定是万位
			//           else if (i%4==0 && i!=0)
			//               R=this.UNUM[S.charAt(i)]+this.UNIT[4]+R
			//           //其它位则对应 个十百千,请注意个位的特殊处理方式
			//           else
			//               R=this.UNUM[S.charAt(i)]+this.UNIT[(i+1)%4]+R
			//       }
			//  　　　　 return R;
			//   },
			//   TrimZero(R){
			//       return R.replace(/零([十百千])/ig,"零")
			//       .replace(/亿零{4,4}万/ig,"亿")
			//       .replace(/([亿万])零{4,4}/ig,"$1")
			//       .replace(/零{2,3}/ig,"零")
			//       .replace(/([十百千])零{1,3}([万|亿])/ig,"$1$2")
			//       .replace(/(.*)零$/,"$1")
			//       .replace(/零亿/ig,"零")
			//       .replace(/^一十/,"十");
			//   },
			//  ToUpper(s){
			// 	return this.TrimZero(this.ToFullUpper(s));
			//   }
		}
	}
</script>

<style lang="less" scoped>
	   //@media print只有调用打印的时候会生效
		@media print {  
		  //解决断行问题 是因为我设置了页边距，目前去掉页边距就好了
		  // @page {
		  //   margin: 60px 0 80px;
		  // }
		   // 设置文章标题字体
		  // .article_content { font-size: 20px !important;}  
		  // .title { font-size: 28px !important; }
		  .queryTable {
		    -webkit-print-color-adjust: exact;
		    -moz-print-color-adjust: exact;
		    -ms-print-color-adjust: exact;
		    print-color-adjust: exact;
		  }
		}
	.scale-title{
		color:#303133;font-size:20px;font-weight:700;line-height:24px;text-align:center;padding-bottom: 10px;
	}
	// .basic-info{
	// 	font-size: 16px; margin: 10px 0; color: black
	// }
	.quest-deil{
		font-size: 18px; padding: 10px 0; color: black;
		// &.mag{
		// 	padding-bottom: 10px;
		// }
	}
	.measure-msg{
		margin-bottom: 10px;
	}
	.advice{
		margin-top: 40px;
	}
	.user-info{
		display: flex;
		padding: 5px 0;
		p{
			margin-right: 5px;
		}
		.line{
			display: inline-block;
			width:80px;
			margin-left: 10px;
			border-bottom: 1px solid #044d5a;
		}
		// >div{
		// 	width:37%;
		// 	&.small{
		// 		flex:1
		// 	}
		// 	margin-right: 1%;
		// 	display: flex;
		// 	.line{
		// 		flex:1;
		// 		border-bottom: 1px solid #044d5a;
		// 	}
		// }
	}
	
	.queryTable {
		// max-height: 500px;
		// overflow: auto;
	
		table {
			width: 100%;
			transition: all 0.25s;
	
			&:hover {
				background: rgb(236, 235, 211);
			}
		}
	
		.queryTable-title {
			font-weight: 700;
			font-size: 18px;
			color: #044d5a;
		}
	.queryTable-item-box{
		display: flex;
		flex-wrap: wrap;
		padding: 15px 0;
	}
		.queryTable-item {
			margin-left: 30px;
			color: rgb(48, 47, 47);
			font-size: 16px;
			p{
				display: flex;
				align-items: center;
				padding: 0 10px;
				span{
					margin-left: 10px;
				}
			}
			
		}
	}
</style>